<template>
  <div class="row no-gutters">
    <div class="col-12">
      <div class="row no-gutters">
        <div class="col-10 offset-1 col-lg-12 offset-lg-0">
          <div class="m-card text-center">
            <h1 class="o-heading o-heading--xl">
              {{ $t("codeReferto.title") }}
            </h1>
            <p>
              {{ $t("codeReferto.subtitle") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--
    <b-alert show variant="warning" class="text-center mt-3 mb-3 col-10 offset-1 p-5" dismissible>
      <p>Se non risulta possibile visualizzare il referto o la fattura scaricata assicurarsi di consentire al browser sempre l'uso dei popup cliccando sulla seguente icona nella barra dell'indirizzo:</p>
      <b-img fluid center src="../../assets/blocco-popup.png" alt="Blocco popup"></b-img>
    </b-alert>    
    -->

    <div class="col-10 offset-1 col-xl-4 pt-5">
      <div class="m-card ">
        <div class="m-card__img">
          <b-img-lazy
            src="../../assets/code_ref.svg"
            alt="operatore"
          ></b-img-lazy>
        </div>
        <div class="m-card__form">


          <b-form-group
            id="cod_episodio"
            :label="this.$t('codeReferto.cod_episodio')"
            label-for="cod_episodio-input"
            class="m-input"
          >
            <b-form-input
              id="cod_episodio-input"
              v-model="cod_episodio"
              type="text"

              :placeholder="this.$t('codeReferto.cod_episodio_placeholder')"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="mps"
            :label="this.$t('codeReferto.MSP')"
            label-for="MSP-input"
            class="m-input"
          >
            <b-form-input
              id="mps-input"
              v-model="mps"
              type="text"

              :placeholder="this.$t('codeReferto.MSP_placeholder')"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="nascita"
            :label="this.$t('codeReferto.nascita')"
            label-for="nascita-input"
            class="m-input"
          >
            <b-form-input
              id="nascita-input"
              v-model="nascita"
              type="date"
              :placeholder="this.$t('codeReferto.nascita_placeholder')"
              required
            ></b-form-input>
          </b-form-group>
          <b-alert  variant="danger" id="errore_danger" v-model="isErr" >{{this.errMessage!=''? $t("codeReferto.errors."+this.errMessage).replace('@@tentativi@@',this.tentativi).replace("@@minuti@@",lock_min):"" }}</b-alert>
          <div class="m-card__action mt-2">

            <b-button @click="apriFile()" class=" mb-2 text-light " variant="secondary" size="md" :pippo="nascita==='' || nascita===null || mps ===null ||mps==='' ||cod_episodio==='' || cod_episodio===null">{{
              $t("codeReferto.action")
            }}</b-button>            

            <u><a class="ml-3" href="#" @click="openHelp()">Dove trovo questi dati?</a></u>            
          </div>
        </div>


      </div>
    </div>

    <div class="col-10 offset-1 col-xl-5 pt-5">
      <div class="m-card">        
        <p class="mt-3">{{ $t("codeReferto.txt_1") }}</p>        
        <ul class="mt-3">
          <li >{{ $t("codeReferto.li_1") }}</li>
          <li v-if="false">{{ $t("codeReferto.li_2") }}</li>
          <li v-if="false">{{ $t("codeReferto.li_3") }}</li>
          <li>{{ $t("codeReferto.li_4") }}</li>
        </ul>
      <p class="mt-3">{{ $t("codeReferto.txt_2") }}</p>                
      </div>
    </div>    

    <b-modal
        id="help"
        centered
        modal-class="o-modal"
        content-class="o-modal__content"
        header-class="o-modal__header"
        dialog-class="o-modal__dialog"
        body-class="o-modal__body o-modal__body--response "
        ok-title="ok-title"
        :cancel-disabled="true"
        :hide-footer="true"
        :hide-header-close="true"
        :hide-header="true"
        size="lg"
    >
      <p class="mt-3  text-uppercase font-weight-bold h3" >{{$t("codeReferto.example")}}</p>
      <br>
      <b-img-lazy src="../../assets/istruzioni_singolo_referto.png" fluid alt="Help"></b-img-lazy>
    </b-modal>  

  </div>
</template>

<script>
import apiAxios from "@/libs/apiAxios";
import router from "@/router";

export default {
  name: "AppCodeReferto",

  data() {
    return {
      passwordHide: "*********",
      isErr:false,
      errMessage:'',
      data: [],
      cod_episodio:'',
      nascita:'',
      mps:'',
      tentativi:0,
      lock_min:60
    };
  },
  computed: {},

  methods: {
    openHelp(){
      this.$bvModal.show('help');
    },

    signalError(bError,tError)
    {
      this.isErr=bError;
      this.errMessage=tError;
    },
    async getFile(codiceMSP,episodio,data_nascita) {
      if (data_nascita==='' || data_nascita===null || codiceMSP ===null ||codiceMSP==='' ||episodio==='' || episodio===null)
      {
        this.signalError(true,'DATI_INCOMPLETI');
        return;
      }
      let res='';
      let dataF = new Date(data_nascita);
      dataF = dataF.getFullYear() + "-" + ((dataF.getMonth()+1)<10 ? "0" : "") + (dataF.getMonth()+1) + "-" + (dataF.getDate()<10 ? "0" : "") + dataF.getDate();
      const data ={
        codiceMSP:codiceMSP==null?'':codiceMSP,
        episodio:episodio==null?'':episodio,
        data_nascita:dataF
      };

      this.signalError(false,'');

      await apiAxios.post(`/jit/singoloReferto`, data)
          .then(response => {
            /* console.log(response); */
            const data= response.data.SingoloRefertoResult;

           if (data.success)
           {
             this.signalError(false,'');
             sessionStorage.setItem("singolo",true);
             sessionStorage.setItem('referti',JSON.stringify({referti:data.data.referti,total:data.data.referti.length,pageSize:data.data.referti.length,page:0,pages:1}));
             router.push("/referti");
           }
           else {
             this.tentativi=data.remain;
             this.lock_min=data.lock_min;
             if (data.error==='NO_MATCH' && data.remain===0) {
               data.error = "BLOCK_EPISODE";
             }
             this.signalError(true,data.error);           }
          })
          .catch((error) => {
            console.log(error)
            res="";
          });

      this.fileData=res;

    },
    pdfOnNewWindow(data){
      const sliceSize=512;
      const byteCharacters = atob(data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }


      const file = new Blob(
          byteArrays,
          {type: 'application/pdf'});
      //Build a URL from the file
      //const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      //window.open(fileURL);
      //location.href = fileURL;
      this.downloadFile(file, "Referto_"+this.data.cod_episodio+".pdf");      
    },

    downloadFile(blob, fileName){
      const link = document.createElement('a');
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },

    apriFile: function (){
      console.log("Apri");
      this.getFile(this.mps,this.cod_episodio,this.nascita);



      //console.log( getJITRefertoFile64('a','b','c'))
    }
  },
};
</script>
