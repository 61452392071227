<template>
  <div class="codiceDownloadReferto">
    <div class="row no-gutters">
      <div class="col-lg-10 offset-lg-1">
        <app-code-referto></app-code-referto>
      </div>
    </div>
  </div>
</template>
<script>
import AppCodeReferto from "../components/organisms/AppCodeReferto.vue";
export default {
  components: { AppCodeReferto },
  name: "codiceDownloadReferto",

  data() {
    return {
      registration: {
        firstName: "Giacomo",
        lastName: "Magnani",
        codiceFiscale: "mgngcm90c29c618x",
        straniero: false,
        birthDate: "1990-03-29T00:00:00+00:00",
        gender: "M",
        country: "Brescia",
        nationBorn: "Italia",
        cityBornFromTaxCode: "CHIARI",
        username: "mgngcm90c29c618x",
        email: "jack.magnakoki@gmail.com",
        tel: "3293978528",
        invoice: {
          data: {
            administrative: "Lombardia",
            city: "Palazzolo sull'Oglio",
            country: "Italia",
            countryCode: "it",
            county: "Brescia",
            name: "Via Luigi Pirandello 27",
            postcode: "25036",
            value:
              "Via Luigi Pirandello 27, Palazzolo sull'Oglio, Lombardia, Italia",
          },
        },
      },
    };
  },

  methods: {

  },
};
</script>
